.responsive-ordersTable-container {
  width: 100%;
  overflow-x: auto;
}

.picking-table {
  min-width: 1200px;
  max-width: 100%;
}

.order-visual-article-container-horizontal {
  padding: 8px;
  box-shadow: var(--box-shadow);
  margin-right: 22px;
  margin-left: 4px;
}

.order-visual-article-img-container-horizontal {
  width: auto;
  height: 200px;
  border-radius: 5px;
}

.order-visual-article-img-container-horizontal img {
  width: 100%;
  height: 100%;
  object-position: center;
  border-radius: 5px;
}

.ReactVirtualized__Table__row:hover {
  background-color: rgb(238, 238, 238) !important;
}

